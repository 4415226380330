<template>
  <div>
    <CRow ref="shopCategorySection">
      <CCol col="12">
        <CategoryTable
          :items="categoryList"
          :fields="tableFields"
          hover
          striped
          border
          small
          fixed
          caption="店舗カテゴリーリスト"
        />
      </CCol>
      <CElementCover :opacity="0.8" v-if="tableLoading" />
    </CRow>
  </div>
</template>

<script>
import CategoryTable from "../../components/shop_categories/CategoryTable";
import { mapState } from "vuex";

export default {
  name: "ShopCategories",

  components: {
    CategoryTable,
  },

  computed: {
    ...mapState({
      tableLoading: (state) => state.shopCategory.tableLoading,
      categoryList: (state) => state.shopCategory.shopCategoryTable,
    }),
  },

  data() {
    return {
      tableFields: [
        {
          key: "name",
          label: "店舗名",
        },
        {
          key: "parent_name",
          label: "カテゴリー",
        },
        {
          key: "shop_count",
          label: "店舗数",
        },
        {
          key: "action",
          label: "編集",
        },
      ],
    };
  },

  methods: {},

  mounted() {
    this.$store.dispatch("shopCategory/getShopCategoryTable");
  },

  metaInfo: {
    title: "Shop Categories Management",
  },
};
</script>
